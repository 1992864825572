<template>
  <div class="container">
    <div class="toolbar">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="medium"
        v-on:click="addCategory1"
        >添加代理账户</el-button
      >
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      size="small"
      style="width: 100%"
    >
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="username" label="账户" width="220">
      </el-table-column>
      <el-table-column prop="userfullname" label="用户（医院）名" width="220">
      </el-table-column>
      <el-table-column prop="rolename" label="账户角色" width="220">
      </el-table-column>
      <el-table-column prop="categoryname" label="代理等级" width="220">
      </el-table-column>
      <el-table-column label="代理区域" min-width="220">
        <template slot-scope="scope">
          <span>{{
            scope.row.provincename +
            " " +
            scope.row.cityname +
            " " +
            scope.row.districtname
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="注册时间" width="220">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="250">
        <template slot-scope="scope">
          <el-button
            @click="viewDetails(scope.row)"
            type="primary"
            plain
            size="small"
            >查看</el-button
          >
          <el-button
            v-if="scope.row.ishospital == 1"
            @click="viewHospital(scope.row)"
            type="success"
            plain
            size="small"
            >查看免费次数</el-button
          >
          <!--                    <el-button  v-if="scope.row.userlevel === '0'" @click="addChildCategory(scope.row)" type="text" size="small">添加省代理</el-button>-->
          <!--                    <el-button  v-if="scope.row.userlevel === '1'" @click="addChildCategory(scope.row)" type="text" size="small">添加地级市代理</el-button>-->
          <!--                    <el-button  v-if="scope.row.userlevel === '2'" @click="addChildCategory(scope.row)" type="text" size="small">添加区（县）代理人</el-button>-->
          <!--                    <el-button  v-if="scope.row.userlevel === '3'" @click="addChildCategory(scope.row)" type="text" size="small">添加代理医院</el-button>-->
          <!--                    <el-button @click="deleteUser(scope.row)" :disabled="scope.row.userlevel === '0'" type="text" size="small" style="color: #f56c6c">删除账户</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 30]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </div>
    <el-dialog
      append-to-body
      title="新增账户"
      :visible.sync="addDetailDialog"
      v-if="addDetailDialog"
      width="700px"
    >
      <AddEditUserDetails
        :closeAndRefresh="closeAndRefresh"
      ></AddEditUserDetails>
    </el-dialog>

    <el-dialog
      append-to-body
      title="账户详情"
      :visible.sync="viewDetailDialog"
      v-if="viewDetailDialog"
      width="700px"
    >
      <ViewUserDetails :userData="viewDetailObj"></ViewUserDetails>
    </el-dialog>

    <el-dialog
      append-to-body
      title="医院免费次数"
      :visible.sync="viewHospitalDialog"
      v-if="viewHospitalDialog"
      width="500px"
    >
      <div>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="label">医院名称</div>
            <div class="value">{{ hospitalData.hospitalname }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="label">免费次数</div>
            <div class="value">
              <span>{{ hospitalData.freecount }}次</span>
              <el-button
                style="margin-left: 10px"
                type="text"
                @click="updateFreeCount(detail.hospitalcode)"
                >修改次数</el-button
              >
            </div>
          </el-col>
          <el-col :span="12">
            <div class="label">当前剩余免费次数</div>
            <div class="value">
              <span style="line-height: 46px"
                >{{
                  hospitalData.lastfreecount ? hospitalData.lastfreecount : 0
                }}次</span
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api";
import AddEditUserDetails from "../../components/AddEditUserDetails";
import router from "../../router";
import ViewUserDetails from "../../components/ViewUserDetails";

/**
 * userlevel用户等级	String	1 = 省级代理   2=市级代理   3=代理人   4=医院
 */
export default {
  components: { ViewUserDetails, AddEditUserDetails },
  data() {
    return {
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      tableData: [],
      addDetailDialog: false,
      viewDetailObj: null,
      loginData: null,
      viewDetailDialog: false,
      addCategoryParams: {
        //添加下线账户的参数
        userlevel: "",
        category0code: "", //总角色
        category1code: "", //省级代理编码
        category2code: "", //市代理编码
        category3code: "", //代理人编码
        category4code: "", //医院编码
        provinceid: "", //省地区id
        cityid: "", //地级市id
        districtid: "", //区id
        isEdit: false,
      },
      loading: false,
      viewHospitalDialog: false,
      hospitalData: null,
    };
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.addCategoryParams.category0code = this.loginData.usercode;
    if (!this.loginData) {
      this.$message({
        showClose: true,
        message: "用户信息失效，请重新登录",
        type: "warning",
      });
      router.push("/Login");
      return;
    }
    this.initList();
  },
  methods: {
    initList() {
      this.loading = true;
      api
        .getMUserPageList({
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          page: this.pageIndex,
          pageSize: this.pageSize,
          ishospital: 0,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    viewDetails(row) {
      this.viewDetailObj = row;
      this.viewDetailDialog = true;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
    addCategory1() {
      this.addDetailDialog = true;
    },
    closeAndRefresh() {
      this.addDetailDialog = false;
      this.initList();
    },
    deleteUser(row) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api
          .deleteMUser({
            userlevel: row.userlevel,
            categorycode: row.categorycode,
          })
          .then((res) => {
            if (res.data.success === "1") {
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
              });
              this.initList();
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
          })
          .catch(() => {
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
      });
    },
    viewHospital(val) {
      this.loading = true;
      api
        .getHospitalDetail({
          categorycode: val.categorycode,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.viewHospitalDialog = true;
            this.hospitalData = res.data.data;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    updateFreeCount(hospitalcode) {
      this.$prompt("请输入免费次数", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: "输入数字有误，请重新输入",
      }).then(({ value }) => {
        this.loading = true;
        api
          .setHospitalFreeCount({
            hospitalcode: hospitalcode,
            freecount: value,
          })
          .then((res) => {
            if (res.data.success === "1") {
              this.$message({
                showClose: true,
                message: "设置成功",
                type: "success",
              });
              this.viewHospitalDialog = false;
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.toolbar {
  margin-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.label {
  line-height: 30px;
  color: #303133;
}
.value {
  line-height: 30px;
  color: #909399;
}
</style>