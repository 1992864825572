<template>
  <div class="container">
    <el-form
      :model="formData"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      v-loading="loading"
    >
      <el-form-item label="登录账户" prop="username">
        <el-input v-model="formData.username"></el-input>
      </el-form-item>
      <el-form-item label="账户密码" prop="pwd">
        <el-input v-model="formData.pwd"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="userfullname">
        <el-input v-model="formData.userfullname"></el-input>
      </el-form-item>
      <el-form-item label="角色权限" prop="role">
        <el-select
          style="width: 65%"
          v-model="formData.role"
          placeholder="请选择账户角色"
          @change="onRoleChange"
        >
          <el-option
            :label="item.rolename"
            :value="item.rolecode"
            v-for="item in roleList"
            v-bind:key="item.rolecode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账户等级" prop="userlevel">
        <el-select
          style="width: 65%"
          v-model="formData.userlevel"
          placeholder="请选择账户等级"
          @change="onLevelChange"
        >
          <el-option label="省级代理" value="1"></el-option>
          <el-option label="市级代理" value="2"></el-option>
          <el-option label="代理人" value="3"></el-option>
          <!-- <el-option label="医院" value="4"></el-option> -->
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="医院渠道" v-if="formData.userlevel == 4">
        <el-radio-group v-model="formData.channeltype">
          <el-radio :label="0">自己发展的医院</el-radio>
          <el-radio :label="1">妇幼保健协会的医院</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="代理区域" prop="userarea">
        <el-cascader
          style="width: 65%"
          v-model="formData.userarea"
          :options="areaList"
          :props="{ checkStrictly: true }"
          @change="onAreaChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item style="margin-top: 50px; float: right">
        <el-button @click="resetForm('ruleForm')" size="medium" plain
          >重置</el-button
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          size="medium"
          plain
          >立即创建</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api";

export default {
  name: "AddEditUserDetails",
  props: {
    // userlevel: String,//用户等级
    // category0code: String,//总角色
    // category1code: String,//省级代理编码
    // category2code: String,//市代理编码
    // category3code: String,//代理人编码
    // category4code: String,//医院编码
    // provinceid: String,
    // cityid: String,
    // districtid: String,
    closeAndRefresh: Function,
    isEdit: Boolean,
  },
  data() {
    return {
      loading: false,
      url:
        "https://restapi.amap.com/v3/config/district?key=4f3b15d4e9ec39a20d3fb459c65ecf17",
      rules: {
        username: [
          { required: true, message: "请输入登录账户名", trigger: "blur" },
          {
            min: 6,
            max: 25,
            message: "登录账户应大于6位数并小于25位数",
            trigger: "blur",
          },
        ],
        pwd: [
          { required: true, message: "请输入账户密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "密码长度应大于6位并小于16位数",
            trigger: "blur",
          },
        ],
        userfullname: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
        userlevel: [
          { required: true, message: "请选择用户等级", trigger: "change" },
        ],
        userarea: [
          { required: true, message: "请选择代理区域", trigger: "change" },
        ],
        role: [
          { required: true, message: "请选择账户角色", trigger: "change" },
        ],
      },
      formData: {
        username: "",
        pwd: "",
        userfullname: "",
        userlevel: "",
        userarea: "",
        role: "",
        provinceid: "",
        cityid: "",
        districtid: "",
        roleItem: null,
        areaItem: [],
        channeltype: 0,
      },
      areaList: [],
      roleList: [],
    };
  },
  created() {
    api.queryMRoleSelectList().then((res) => {
      this.roleList = res.data.data.filter(
        (e) => e.rolename !== "医院" && e.rolename !== "管理员"
      );
    });
  },
  methods: {
    onLevelChange(value) {
      let url;
      /**
       * userlevel用户等级	String	1 = 省级代理   2=市级代理   3=代理人   4=医院
       */
      switch (value) {
        case "1":
          url = this.url + "&subdistrict=1";
          break;
        case "2":
          url = this.url + "&subdistrict=2";
          break;
        case "3":
          url = this.url + "&subdistrict=3";
          break;
        case "4":
          url = this.url + "&subdistrict=3";
          break;
      }
      this.formData.userlevel = value;
      this.loading = true;
      axios.get(url).then((res) => {
        console.log(res);
        let list = [];
        for (let pIndex in res.data.districts[0].districts) {
          let pItem = res.data.districts[0].districts[pIndex];
          let value = {};
          value.provinceid = pItem.adcode;
          value.label = pItem.name;
          value.value = pItem.adcode;
          if (pItem.districts && pItem.districts.length > 0) {
            value.children = [];
          }
          for (let cIndex in pItem.districts) {
            let cItem = pItem.districts[cIndex];
            let cValue = {
              cityid: cItem.adcode,
              label: cItem.name,
              value: cItem.adcode,
            };
            if (cItem.districts && cItem.districts.length > 0) {
              cValue.children = [];
            }
            for (let dIndex in cItem.districts) {
              let dItem = cItem.districts[dIndex];
              cValue.children.push({
                districtid: dItem.adcode,
                label: dItem.name,
                value: dItem.adcode,
              });
            }
            value.children.push(cValue);
          }
          list.push(value);
        }
        this.areaList = list;
        this.loading = false;
      });
    },
    onAreaChange(value) {
      console.log(value);
      this.formData.areaItem = value;
      // if (value.length === 1){
      //   this.formData.areaItem.push(getAreaItemByCode(value[0],this.areaList));
      // }
      // if (value.length === 2){
      //   let pitem = getAreaItemByCode(value[0],this.areaList);
      //   this.formData.areaItem.push(pitem,getAreaItemByCode(value[1],pitem.children));
      // }
      // if (value.length === 3){
      //   let pitem = getAreaItemByCode(value[0],this.areaList);
      //   let citem = getAreaItemByCode(value[1],pitem.children);
      //   this.formData.areaItem.push(pitem,citem,getAreaItemByCode(value[2],citem.children));
      // }
    },
    onRoleChange(value) {
      this.formData.roleItem = getRoleItemByValue(value, this.roleList);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            username: this.formData.username,
            pwd: this.$md5(this.formData.pwd),
            userfullname: this.formData.userfullname,
            rolecode: this.formData.roleItem.rolecode,
            rolename: this.formData.roleItem.rolename,
          };
          if (this.formData.userlevel == 1) {
            //省
            params.categoryname = "省代理";
            params.provinceid = this.formData.areaItem[0];
            params.ishospital = 0;
          }
          if (this.formData.userlevel == 2) {
            //市
            params.categoryname = "市代理";
            params.provinceid = this.formData.areaItem[0];
            params.cityid = this.formData.areaItem[1];
            params.ishospital = 0;
          }
          if (this.formData.userlevel == 3) {
            //代理人 区
            params.categoryname = "代理人";
            params.provinceid = this.formData.areaItem[0];
            params.cityid = this.formData.areaItem[1];
            params.districtid = this.formData.areaItem[2];
            params.ishospital = 0;
          }
          if (this.formData.userlevel == 4) {
            //医院
            params.categoryname = "医院";
            params.provinceid = this.formData.areaItem[0];
            params.cityid = this.formData.areaItem[1];
            params.districtid = this.formData.areaItem[2];
            params.ishospital = 1;
            params.channeltype = this.formData.channeltype;
          }
          api
            .insertMUser(params)
            .then((res) => {
              if (res.data.success === "1") {
                this.$message({
                  showClose: true,
                  message: "添加成功",
                  type: "success",
                });
                this.closeAndRefresh();
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.errorMsg,
                  type: "warning",
                });
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                message: "请检查网络连接",
                type: "warning",
              });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};

// function getAreaItemByCode(code,areas) {
//   for (let index in areas){
//     let item = areas[index];
//     if (item.value === code.toString()){
//       return item;
//     }
//   }
//   return null;
// }

function getRoleItemByValue(value, roleList) {
  for (let index in roleList) {
    let item = roleList[index];
    if (item.rolecode === value.toString()) {
      return item;
    }
  }
  return null;
}
</script>

<style scoped>
.container {
}
</style>