<template>
    <div class="container">
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="label">
                    账户编号
                </div>
                <div class="value">{{userData.usercode}}</div>
            </el-col>
            <el-col :span="12">
                <div class="label">
                    账户名称
                </div>
                <div class="value">{{userData.userfullname}}</div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="label">
                    账号
                </div>
                <div class="value">{{userData.username}}</div>
            </el-col>
            <el-col :span="12">
                <div class="label">
                    账户等级
                </div>
                <div class="value">{{userData.categoryname}}</div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12" v-if="userData.qrcodeurl">
                <div class="label">
                    医院二维码
                </div>
                <img class="qrcode" :src="userData.qrcodeurl"/>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "ViewUserDetails",
        props: {
            userData: Object,
        },
        data(){
            return {

            }
        },
        created() {
        },
        methods:{

        }
    }
</script>

<style scoped>
    .container{

    }
    .label{
        line-height: 30px;
        color: #303133;
    }
    .value{
        line-height: 30px;
        color: #909399;
    }
    .qrcode{
      width: 150px;
      height: 150px;
    }
</style>