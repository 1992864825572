import { render, staticRenderFns } from "./ViewUserDetails.vue?vue&type=template&id=59758714&scoped=true&"
import script from "./ViewUserDetails.vue?vue&type=script&lang=js&"
export * from "./ViewUserDetails.vue?vue&type=script&lang=js&"
import style0 from "./ViewUserDetails.vue?vue&type=style&index=0&id=59758714&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59758714",
  null
  
)

export default component.exports